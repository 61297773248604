import { Card, Tag } from "../components/ui";
import React from "react";
import { Loading, MediaSkeleton } from "../components/shared";

export const APP_NAME = "The Students Visa";
export const PERSIST_STORE_NAME = "admin";
export const REDIRECT_URL_KEY = "redirectUrl";

export const currencies = [
  { value: "USD", label: "United States Dollar" },
  { value: "GBP", label: "Great Britain Pound" },
  { value: "CAD", label: "Canadian Dollar" },
  { value: "AUD", label: "Australian Dollar" },
  { value: "NZL", label: "New Zealand Dollar" },
  { value: "EUR", label: "Euro" },
];

export const LeadStatus = ({ status, archived }) => {
  switch (status) {
    case 0:
      return (
        <div className="flex gap-1 md:flex-row flex-col">
          <Tag className="rounded-md">New Lead</Tag>{" "}
          {archived && (
            <Tag className="text-red-600 bg-red-100 dark:text-red-100 dark:bg-red-500/20 border-0">
              Archived
            </Tag>
          )}
        </div>
      );
    case 1:
      return (
        <div className="flex gap-1 md:flex-row flex-col">
          <Tag className="bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100  border-0 rounded">
            Sold
          </Tag>
          {archived && (
            <Tag className="text-red-600 bg-red-100 dark:text-red-100 dark:bg-red-500/20 border-0">
              Archived
            </Tag>
          )}
        </div>
      );
    case 2:
      return (
        <div className="flex gap-1 md:flex-row flex-col">
          <Tag className="text-amber-600 bg-amber-100 dark:text-amber-100 dark:bg-amber-500/20  border-0 rounded">
            Not Interested
          </Tag>
          {archived && (
            <Tag className="text-red-600 bg-red-100 dark:text-red-100 dark:bg-red-500/20 border-0">
              Archived
            </Tag>
          )}
        </div>
      );
    case 3:
      return (
        <div className="flex gap-1 md:flex-row flex-col">
          <Tag className="bg-blue-100 text-blue-600 dark:bg-blue-500/20 dark:text-blue-100 border-0 rounded">
            In Progress
          </Tag>
          {archived && (
            <Tag className="text-red-600 bg-red-100 dark:text-red-100 dark:bg-red-500/20 border-0">
              Archived
            </Tag>
          )}
        </div>
      );
    default:
      return <></>;
  }
};

export const LoadingStatCard = (loading) => {
  const avatarSize = 55;

  return (
    <Card bordered>
      <Loading
        loading={loading}
        customLoader={
          <MediaSkeleton
            avatarProps={{
              className: "rounded",
              width: avatarSize,
              height: avatarSize,
            }}
          />
        }
      ></Loading>
    </Card>
  );
};

export const commentTypes = [
  { value: "generalComment", label: "General comment" },
  { value: "contactedThroughMobile", label: "Contacted through mobile" },
  { value: "contactedThroughWhatsapp", label: "Contacted through Whatsapp" },
  { value: "contactedThroughMeeting", label: "Contacted through meeting" },
  { value: "contactedThroughEmail", label: "Contacted through email" },
  { value: "visitedOffice", label: "Visited office" },
];
