import BaseService from './BaseService'
import { Notification, toast } from 'components/ui';

const ApiService = {
    fetchData(param) {
        return new Promise((resolve, reject) => {
            BaseService(param).then(response => {
                resolve(response)
            }).catch(errors => {
                console.log(errors);
                if(errors.response && errors.response.data.message){

                    toast.push(
                         <Notification title={`${errors.response.data.title ? errors.response.data.title : `Server responded with ${errors.code}` }`} type="danger">
                             {errors.response.data.message}
                         </Notification>
                     );

                }else if(errors.message){
                    toast.push(
                        <Notification title={`Server responded with ${errors.code}`} type="danger">
                            {errors.message}
                        </Notification>
                    );
                }
                reject(errors)
            })
        })
    }
}

export default ApiService