import {APP_PREFIX_PATH} from "../constants/route.constant";

let env = process.env.REACT_APP_ENV;

const appConfig = {
    apiPrefix: env === "prod" ? "https://counsellor-api.thestudentsvisa.com/api" : env === "tst" ? "https://counsellor-api.tst.thestudentsvisa.com/api" : "http://localhost:8080/api",
    apiPrefixStudentApi: env === "prod" ? "https://student-api.thestudentsvisa.com/api" : env === "tst" ? "https://student-api.tst.thestudentsvisa.com/api" : "https://dev-student-api.thestudentsvisa.com/api",
    authenticatedEntryPath: `${APP_PREFIX_PATH}/dashboard`,
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    enableMock: true,
    appBackendUrl: env === "prod" ? "https://counsellor-api.thestudentsvisa.com/" : env === "tst" ? "https://counsellor-api.tst.thestudentsvisa.com/" : "http://localhost:8080/",
}

export default appConfig