import { useEffect } from "react";
import { useSelector } from "react-redux";
import { apiGetPusherBeamCredentials } from "services/RealtimeNotificationService";
import * as PusherPushNotifications from "@pusher/push-notifications-web";

const PusherBeam = () => {
  // const interest = process.env.REACT_APP_PUSHER_BEAMS_INTEREST;

  const keycloakUserId = useSelector((state) => state.auth.user.keycloakUserId);

  useEffect(() => {
    const registerCheckBeam = async () => {
      const res = await apiGetPusherBeamCredentials();
      if (res.data) {
        const instanceId = res.data;

        const beamsClient = new PusherPushNotifications.Client({
          instanceId,
        });

        beamsClient
          .getUserId()
          .then((userId) => {
            if (String(userId) !== String(keycloakUserId)) {
              console.log("stopped");
              return beamsClient.stop();
            } else {
              console.log(userId + " : " + keycloakUserId);
            }
          })
          .catch(console.error);
      }
    };
    if (keycloakUserId) {
      registerCheckBeam();
    }
  }, []);

  return <div></div>;
};

export default PusherBeam;
