import ApiService from "./ApiService";

export async function getUser(data) {
  return ApiService.fetchData({
    url: `/user/details`,
    method: "get",
    data,
  });
}

export async function getAgents(data) {
  return ApiService.fetchData({
    url: `/user/agents/`,
    method: "get",
    data,
  });
}
export async function postAgent(data) {
  return ApiService.fetchData({
    url: `/user/agents/`,
    method: "post",
    data,
  });
}

export async function putAgent(data) {
  return ApiService.fetchData({
    url: `/user/agents/`,
    method: "put",
    data,
  });
}
