import { createSlice } from "@reduxjs/toolkit";

import ApiService from "services/ApiService";

export async function apiCreateStudent(data) {
  return ApiService.fetchData({
    url: `/user/leads/manual`,
    method: "post",
    data,
  });
}

export const studentSlice = createSlice({
  name: "student",
  initialState: {
    student: { name: "", email: "", phone: "" },
    open: false,
  },
  reducers: {
    setStudent: (state, action) => {
      state.student.name = action.payload;
    },
    openStudentDrawer: (state, action) => {
      state.open = action.payload;
    },
  },
});

export const { setStudent, openStudentDrawer } = studentSlice.actions;

export default studentSlice.reducer;
