import ApiService from "./ApiService";

export async function apiDeleteNotification(params) {
  return ApiService.fetchData({
    url: `/user/notifications`,
    method: "delete",
    params,
  });
}

export async function apiGetAllNotifications(params) {
  return ApiService.fetchData({
    url: `/user/notifications`,
    method: "get",
    params,
  });
}

export async function apiAddNotification(data) {
  return ApiService.fetchData({
    url: `/user/notifications`,
    method: "post",
    data,
  });
}
export async function apiReadAllNotifications(data) {
  return ApiService.fetchData({
    url: `/user/notifications/read-all`,
    method: "post",
    // data,
  });
}
export async function apiReadOneNotification(params) {
  return ApiService.fetchData({
    url: `/user/notifications/read-one`,
    method: "post",
    params,
  });
}

export async function apiGetPusherChannelCredentials(params) {
  return ApiService.fetchData({
    url: `/user/pusher/channel-credentials`,
    method: "get",
    // params,
  });
}
export async function apiGetPusherBeamCredentials(params) {
  return ApiService.fetchData({
    url: `/user/pusher/beam-credentials`,
    method: "get",
    // params,
  });
}

export async function apiTriggerPusherEvent(params) {
  return ApiService.fetchData({
    url: `/user/pusher/trigger-event`,
    method: "get",
    // params,
  });
}
